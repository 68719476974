// src/Header.js
import React from 'react';
import styled from 'styled-components';

const MainHeading = styled.h1`
  font-size: 1.5em;
  color: #666;
  margin-bottom: 10px;
`;

const Heading = styled.h2`
  font-size: 2.5em;
  color: #333;
  margin: 20px 0;
`;

const Header = () => (
  <>
    <MainHeading>I Really Wish I Had A PP, doesn't need to be big.</MainHeading>
    <Heading>Cumming Soon.</Heading>
  </>
);

export default Header;
