// src/App.js
import React from 'react';
import GlobalStyle from './GlobalStyle';
import Header from './Header';
import styled from 'styled-components';
import comingSoonImage from './pp.png'; // Make sure to place your image in the src folder

const Container = styled.div`
  text-align: center;
`;

const Image = styled.img`
  width: 50%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Container>
        <Header />
        <a href="https://pallet.exchange/leaderboard" target="_blank" rel="noopener noreferrer">
          <Image src={comingSoonImage} alt="Coming Soon" />
        </a>
      </Container>
    </>
  );
}

export default App;
